/**
 * @param {String} title - new document title to set
 */
export function setTitle (title)
{
  document.title = title
}

/**
 * Sets content in the page's `<meta name="description" content="...">` tag.
 * It does nothing if no such meta tag is present.
 * 
 * @param {String} content - new content to set
 */
export function setMetaDescription (content)
{
  const m = document.querySelector("meta[name='description']")
  if (!m)
  {
    return
  }
  m.setAttribute("content", content)
}
