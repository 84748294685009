/** Use fallbackTitle for routes without entry in `titles` */
export const fallbackTitle = "Der Ruhecheck"

/** Titles maps route names to titles. */
export const titles = {
  Contact: 'Kontakt | Wir freuen uns über Deine Nachricht',
  FAQS: 'FAQS | Die wichtigsten Fragen und Antworten',
  Home: 'Ruhecheck | Jetzt herausfinden, wie laut die Wohnung ist',
  Ruhecheck: 'Ruhecheck Ergebnisse | Alle Infos zur Lärmbelastung',
  API: 'Die Ruhecheck API | Schnittstelle zum Lärmtest einer Adresse',
  About: 'Über uns | Unser Ziel und das Team',
  ExampleCheck: 'Ruhecheck Beispiel | So sieht ein Test der Lärmbelastung aus'
}

export const fallbackMetaDescription = "Der Ruhecheck hilft lärmsensiblen Menschen, die richtige Wohnung zu finden. Beispielsweise hilft ein frühzeitiger Ruhecheck, eine laute Wohnung auszuschließen und sich Zeit für Bewerbung und Besichtigung zu sparen."

export const metaDescriptions = {
  Contact: 'Wir freuen uns über Deine Nachricht und helfen gerne weiter.',
  FAQS: 'Die FAQS beantworten deine Fragen: Wie funktioniert der Ruhecheck? Welcher Lärm wird angezeigt? Kann man sich auf die Ergebnisse verlassen? Woher kommen die Daten? Und mehr...',
  Home: 'Der Ruhecheck informiert dich über Lärmprobleme in deiner gesuchten Wohnung. Lärmdaten des Umweltbundesamtes und Open Street Map garantieren die Qualität der Ergebnisse. Einfach Adresse und optional Baujahr angeben und kostenlos starten.',
  Ruhecheck: 'Im Ruhecheck Lärmprofil siehst du detailliert, wo es in der neuen Wohnung Lärmprobleme geben könnte. Wir kombinieren die Analyse von Umgebungslärm und Hellhörigkeit, damit du die perfekte Wohnung findest.',
  API: 'Die Ruhecheck API stellt alle Ergebnisse des Ruhechecks als flexible und performante Schnittstelle bereit. Z.B. zur Einbettung in Ihr Immobilienportal oder Website.',
  About: 'Unser Ziel ist es, Lärm überall transparent zu machen und dafür sorgen, dass es leiser wird. Wir sind ein Team von Software Entwicklern und Akustikexperten, die in Ruhe leben und arbeiten wollen.',
  ExampleCheck: 'Das Ruhecheck Beispiel zeigt dir, wie ein typischer Test der Lärmbelastung aussieht, hier für eine belebte Adresse in Berlin. So siehst du, was du von einem Check erwarten kannst.'
}
