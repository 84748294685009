import { ActionContext } from 'vuex';

interface AddressData 
{
  address: string;
  lat: number;
  lon: number; 
  center: number[];
};

interface State
{
  lon: number;
  lat: number;
  center: number[];
  address: string;
};

const state: State = {
  lon: 0,
  lat: 0,
  center: [] as number[],
  address: ''
};

const getters = {

};

const mutations = {
  setAddressData (state: State, addressData: AddressData)
  {
    state.lon = addressData.lon
    state.lat = addressData.lat
    state.center = addressData.center
    state.address = addressData.address;
  },
};

const actions = {

};

export default 
{
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
