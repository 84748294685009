import Vue from 'vue';
import Vuex from 'vuex';
import address from './modules/address';
import noiseData from './modules/noiseData';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    airNoiseAlertFromFeedback: false,
  },
  mutations: {
    setAirNoiseAlertFromFeedback (state, flag: boolean)
    {
      state.airNoiseAlertFromFeedback = flag;
    }
  },
  actions: {
   
  },
  modules: {
    address,
    noiseData
  }
});
