<template>
  <div>
    <v-snackbar
      v-model="showCookieBar"
      timeout="-1"
    >
      Um eine akkurate Lokalisierung und exakte Ergebnisse zu gewährleisten, benutzen wir die dazu technisch notwendigen Cookies. Durch die Verwendung der Seite erklärst du dich mit unserer 
      <router-link to="/datenschutz">Datenschutzrichtlinie</router-link>
      einverstanden.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="orange"
          text
          v-bind="attrs"
          @click="showCookieBar=false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data () 
  {
    return {
      showCookieBar: true
    }
  }
}
</script>
