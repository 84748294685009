function parseAddress (address: any)
{
  const parsedAddress =
      {
        houseNumber: '',
        road: '',
        suburb: '',
        city: '',
        postcode: '',
        countryCode: ''
      }

  if (address.house_number)
  {
    parsedAddress.houseNumber = address.house_number
  }
  else
  {
    parsedAddress.houseNumber = address.street_number
  }

  if (address.road)
  {
    parsedAddress.road = address.road
  }
  else if (address.street)
  {
    parsedAddress.road = address.street
  }

  else if (address.street)
  {
    parsedAddress.road = address.street
  }
  else if (address.footway)
  {
    parsedAddress.road = address.footway
  }
  else if (address.street_name)
  {
    parsedAddress.road = address.street_name
  }
  else if (address.residential)
  {
    parsedAddress.road = address.residential
  }
  else if (address.path)
  {
    parsedAddress.road = address.path
  }
  else if (address.pedestrian)
  {
    parsedAddress.road = address.pedestrian
  }
  else if (address.road_reference)
  {
    parsedAddress.road = address.road_reference
  }
  else if (address.road_reference_intl)
  {
    parsedAddress.road = address.road_reference_intl
  }
  else if (address.square)
  {
    parsedAddress.road = address.square
  }
  else if (address.place)
  {
    parsedAddress.road = address.place
  }

  if (address.suburb)
  {
    parsedAddress.suburb = address.suburb
  }
  else if (address.neighbourhood)
  {
    parsedAddress.suburb = address.neighbourhood
  }
  else if (address.city_district)
  {
    parsedAddress.suburb = address.city_district
  }
  else if (address.quarter)
  {
    parsedAddress.suburb = address.quarter
  }
  else if (address.borough)
  {
    parsedAddress.suburb = address.borough
  }
  else if (address.city_block)
  {
    parsedAddress.suburb = address.city_block
  }
  else if (address.residential)
  {
    parsedAddress.suburb = address.residential
  }
  else if (address.commercial)
  {
    parsedAddress.suburb = address.commercial
  }
  else if (address.industrial)
  {
    parsedAddress.suburb = address.industrial
  }
  else if (address.subdivision)
  {
    parsedAddress.suburb = address.subdivision
  }
  else if (address.allotments)
  {
    parsedAddress.suburb = address.allotments
  }
  
  if (address.city)
  {
    parsedAddress.city = address.city
  }
  else if (address.town)
  {
    parsedAddress.city = address.town
  }
  else if (address.village)
  {
    parsedAddress.city = address.village
  }
  else if (address.hamlet)
  {
    parsedAddress.city = address.hamlet
  }
  else if (address.locality)
  {
    parsedAddress.city = address.locality
  }
  else if (address.croft)
  {
    parsedAddress.city = address.croft
  }

  if (address.postcode)
  {
    parsedAddress.postcode = address.postcode
  }
  else if (address.partial_postcode)
  {
    parsedAddress.postcode = address.partial_postcode
  }

  // for some addresses in city states no city is returned, e.g. berlin
  if (!parsedAddress.city && address.state)
  {
    parsedAddress.city = address.state
  }

  // for some addresses in city states no city is returned, e.g. berlin
  if (!parsedAddress.road)
  {
    if (address.place)
    {
      parsedAddress.road = address.place
    }
    else if (address.hamlet)
    {
      parsedAddress.road = address.hamlet
    }
  }

  // for villages there may not be a suburb or similar
  if (!parsedAddress.suburb && parsedAddress.city)
  {
    parsedAddress.suburb = parsedAddress.city
  }

  parsedAddress.countryCode = address.country_code

  return parsedAddress
}

function parseCity (address: any): string 
{
  if (address.city) return address.city
  else if (address.town) return address.town
  else if (address.village) return address.village
  else if (address.hamlet) return address.hamlet
  else if (address.locality) return address.locality
  else if (address.croft) return address.croft
  // for some addresses in city states no city is returned, e.g. berlin
  else if (address.state) return address.state
  else return ''
}

export { parseAddress, parseCity }
