
import Vue from 'vue';

export default Vue.extend({
  props: {
    imgName: {
      type: String,
      default: null
    },
    iconName: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    overlay: {
      type: String,
      default: null
    }
  }
});
