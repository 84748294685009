import Vue from 'vue'
import Router from 'vue-router'

import PageHome from '@/pages/PageHome'
import { setMetaDescription, setTitle } from "@/utils/dom.js"
import { fallbackMetaDescription, fallbackTitle, metaDescriptions, titles } from "@/utils/seoTexts.js"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: PageHome
    },
    // {
    //   path: '/ruhecheck',
    //   name: 'Ruhecheck',
    //   component: () => import("@/pages/PageWohnungscheck"),
    //   props: route => ({ ...route.query })
    // },
    // {
    //   path: '/ruhecheck-beispiel',
    //   name: 'ExampleCheck',
    //   component: () => import("@/pages/PageWohnungscheckExample"),
    // },
    {
      path: '/FAQS',
      name: 'FAQS',
      component: () => import("@/pages/PageFAQS"),
    },
    {
      path: '/about',
      name: 'About',
      component: () => import("@/pages/PageAbout"),
    },
    {
      path: '/kontakt',
      name: 'Contact',
      component: () => import("@/pages/PageContact"),
    },
    {
      path: '/API',
      name: 'API',
      component: () => import("@/pages/PageAPI"),
    },
    {
      path: '/datenschutz',
      name: 'Datenschutz',
      component: () => import("@/pages/PageDatenschutz"),
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: () => import("@/pages/PageImpressum"),
    }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) =>
{
  // Title and Meta defined in index.html
  if (to.name !== "Home")
  {
    // Set route's title or default to fallbackTitle.
    const t = titles[to.name]
    t ? setTitle(t) : setTitle(fallbackTitle)

    // Set route's meta description or default to fallbackTitle.
    const m = metaDescriptions[to.name]
    m ? setMetaDescription(m) : setMetaDescription(fallbackMetaDescription)
  }

  next()
})

export default router
