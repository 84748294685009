import axios from 'axios'
import { fromLonLat } from 'ol/proj'
import { parseCity } from './parseAddress'

interface Bbox {
  xMin: number,
  yMin: number,
  xMax: number,
  yMax: number
}

function calculateBBox (lon: number, lat: number, sideLength: number) : Bbox
{
  const [xCenter, yCenter] = fromLonLat([lon, lat])
        
  const xMin = xCenter - sideLength / 2
  const xMax = xCenter + sideLength / 2
  const yMin = yCenter - sideLength / 2
  const yMax = yCenter + sideLength / 2

  return {
    xMin: xMin,
    yMin: yMin,
    xMax: xMax,
    yMax: yMax
  }
}

interface geoCodingResults {
  lon: string, 
  lat: string,
  streetName: string,
  houseNumber: string,
  postcode: string,
  city: string,
  countryCode: string
}

async function geoCodeNominatim (address: string): Promise<geoCodingResults>
{
  const url = `https://nominatim.openstreetmap.org/search.php?q=${encodeURI(address)}&polygon_geojson=1&format=jsonv2&addressdetails=1`

  const res = await axios.get(url)
  const city = parseCity(res.data[0].address)
  const data = res.data[0]
  return {
    lon: data.lon,
    lat: data.lat,
    streetName: data.address.road,
    houseNumber: data.address.house_number,
    postcode: data.address.postcode,
    city,
    countryCode: data.address.country_code
  }
}

async function geoCodeGoogle (address: string): Promise<geoCodingResults>
{
  const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`

  const res = await axios.get(url)
  const location = res.data.results[0].geometry.location
  const addressComponents = res.data.results[0].address_components
  return {
    lon: location.lng.toString(),
    lat: location.lat.toString(),
    streetName: addressComponents[1].long_name,
    houseNumber: addressComponents[0].long_name,
    postcode: addressComponents.at(-1).long_name,
    city: addressComponents[2].long_name,
    countryCode: addressComponents.at(-2).short_name
  }
}

async function geoCodeAddress (address: string): Promise<geoCodingResults>
{
  try 
  { 
    var { lon, lat, streetName, houseNumber, postcode, city, countryCode } = await geoCodeNominatim(address);
    if (typeof houseNumber === 'undefined')
    {
      var { lon, lat, streetName, houseNumber, postcode, city, countryCode } = await geoCodeGoogle(address);
    }
  }
  catch
  {
    var { lon, lat, streetName, houseNumber, postcode, city, countryCode } = await geoCodeGoogle(address);
  }
  return {
    lon,
    lat,
    streetName,
    houseNumber,
    postcode,
    city,
    countryCode
  }
}

export 
{ 
  calculateBBox, Bbox, geoCodeAddress
}
