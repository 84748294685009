<template>
  <v-app style="background: rgba(0,0,0,0);">
    <v-container
      :class="showHome ? 'fill-height back' : 'fill-height'"
      fluid
    >
      <the-header :show-home-header="showHome" />
      <v-main class="mt-3">
        <router-view @show-background-image="showBackgroundImage = true"/>
      </v-main>
    </v-container>
    <the-footer />
  </v-app>
</template>

<script>
import TheFooter from '@/components/TheFooter';
import TheHeader from '@/components/TheHeader';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
  computed: {
    showHome () 
    {
      return this.$route.fullPath === '/'
    }
  }
};
</script>

<style>
 .v-card__title {
   word-break: break-word !important;
   hyphens: auto !important;
 }
 .back {
    background-image: url('~@/assets/wohnfroh_img_cropped_dark.jpg');
    background-position: 0% 30%;
    background-repeat: no-repeat;
    background-size: cover;
  }
@media (min-width: 48em) {
    .back {
      background-size: 100% 60%;
    }
  }
@media (min-width: 140em) {
    .back {
      background-size: 100% 50%;
    }
  }
</style>

<style lang="sass">
  @import '../node_modules/typeface-roboto/index.css'
</style>
