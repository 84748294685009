
import EvaluationItem from '@/components/EvaluationItem.vue';
import TheAwards from '@/components/TheAwards.vue';
import { geoCodeAddress } from '@/utils/geoFunctions';
import { setYearOfConstruction } from '@/utils/helperFunctions';
import Vue from 'vue';

export default Vue.extend({
  components: {
    EvaluationItem, 
    TheAwards
  },
  data () 
  {
    return {
      yearOfConstructionKnowledge: ['Ich kenne das Baujahr', 'Ich kenne den ungefähren Bauzeitraum', 'Ich kann zum Baujahr nichts sagen'],
      yearOfConstructionKnowledgeSelected: '',
      constructionRanges: ['Altbau Gründerzeit (100 Jahre und älter)', 'Altbau jünger', 'Neubau', 'Weiß nicht'],
      addressInput: '',
      addressCorrect: null,
      addressHint: 'z.B. Unter den Linden 30, 10117 Berlin',
      addressValidated: '',
      lastSearches: [] as string[],
      yearOfConstruction: '',
      yearOfConstructionUnknown: false,
      constructionRange: '',
    }
  },
  created () 
  {
    this.getLastSearches()
  },
  methods: {
    comboboxUpdateAddress (currentValue: string)
    {
      this.addressInput = currentValue
    },
    async doNoiseCheck () 
    {
      try
      {
        const { lon, lat, streetName, houseNumber, postcode, city } = await geoCodeAddress(this.addressInput)
        this.trackLastSearches()
        this.$gtag.event('noiseCheckRequested', 
          {
            method: 'Google', 
            event_label: this.addressInput + '+' + this.yearOfConstruction 
          })
        this.trackNoiseChecks()
        const yearOfConstruction = setYearOfConstruction(this.yearOfConstruction, this.constructionRange)
        this.$router.push({
          name: 'Ruhecheck',
          query: {
            strasse: streetName,
            hausnummer: houseNumber,
            plz: postcode,
            stadt: city,
            lat: lat,
            lon: lon,
            baujahr: yearOfConstruction,
          }
        })
      }
      catch
      {
        this.addressCorrect = false as any
        this.addressHint = 'Leider haben wir die Adresse nicht gefunden. Ist alles richtig geschrieben?'
        this.$gtag.event('addressNotFound', 
          {
            method: 'Google', 
            event_label: this.addressInput 
          })
      }
    },
    getLastSearches ()
    {
      const lastSearches = window.localStorage.getItem('lastSearches')

      if (lastSearches) 
      {
        this.lastSearches = [...new Set(JSON.parse(lastSearches))] as string[]
      }
    },
    trackLastSearches () 
    {
      this.lastSearches.push(this.addressInput)
      window.localStorage.setItem('lastSearches', JSON.stringify(this.lastSearches))
    },
    trackNoiseChecks () 
    {
      const noiseChecksRequested = Number(window.localStorage.getItem('noiseChecksRequested'))

      if (noiseChecksRequested) 
      {
        window.localStorage.setItem('noiseChecksRequested', String(noiseChecksRequested + 1))
      }

      else 
      {
        window.localStorage.setItem('noiseChecksRequested', '1')
      }
    }
  }
})
