
import Vue from 'vue';

export default Vue.extend({
  props: {
    showHomeHeader: {
      type: Boolean,
      required: true
    }
  },
  data () 
  {
    return {
      pages: [
        {
          name: 'Neuer Ruhecheck',
          link: '/'
        },
        {
          name: 'API',
          link: '/API'
        },
        {
          name: 'FAQS',
          link: '/FAQS'
        },
        {
          name: 'Über uns',
          link: '/about'
        },
        {
          name: 'Kontakt',
          link: '/kontakt'
        }
      ]
    }
  }
});
