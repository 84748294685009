import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css';
import router from './router';
import store from "@/store";
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(
  VueLayers, {
    dataProjection: 'EPSG:3857'
  }) 
Vue.use(
  VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
  });

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
