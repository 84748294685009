import { Colors } from './globalConstants';

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
const calcDistance = function (lat1: number, lon1: number, lat2: number, lon2: number): number 
{
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const lat1Rad = toRad(lat1);
  const lat2Rad = toRad(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
  const d = R * c;
  return d;
}

//converts snake to camelCase
const toCamel = (s: string): string => 
{
  return s.replace(/([-_][a-z])/ig, ($1) => 
  {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

// Converts numeric degrees to radians
function toRad (Value: number): number 
{
  return Value * Math.PI / 180;
}

interface myCallbackType { (argument: string, index: number, array: string[]): void }

async function asyncForEach (array: string[], callback: myCallbackType) 
{
  for (let index = 0; index < array.length; index++) 
  {
    await callback(array[index], index, array);
  }
}

function setColor (points: Number) 
{
  ""
  if (points < 20) return Colors.Red;
  else if (points < 40) return Colors.Orange
  else if (points < 60) return Colors.Yellow
  else if (points < 80) return Colors.LightGreen
  else if (points <= 100) return Colors.DarkGreen;
  else return Colors.Black;
}

function setYearOfConstruction (year: number | string, range: string): string
{
  let yearOfConstruction = -1
  if (year) 
  {
    return year.toString()
  }
  if (range) 
  {
    if (range === 'Altbau Gründerzeit (100 Jahre und älter)') 
    {
      yearOfConstruction = 1910
    }
    else if (range === 'Altbau jünger') 
    {
      yearOfConstruction = 1960
    }
    else if (range === 'Neubau') 
    {
      yearOfConstruction = 2020
    }
  }
  return yearOfConstruction.toString()
}

export {
  calcDistance, 
  asyncForEach, 
  setColor,
  toCamel,
  setYearOfConstruction
};
