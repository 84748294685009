/* eslint-disable camelcase */
import { APIResponse, TransportationNoise, Flags, NoiseEvaluation, Quality, NoiseRegulation } from '@/utils/backendConnector';
import { Feedback } from '@/utils/userFeedbackFunctions';

interface State
{
  noiseScores: APIResponse["noise_scores"];
  transportationNoiseSources: TransportationNoise;
  flags: Flags;
  poiNoiseSources: NoiseEvaluation[];
  noiseInsulation: NoiseRegulation;
  quality: Quality;
  userFeedback: Feedback[];
};

const state: State = {
  noiseScores: {} as APIResponse["noise_scores"],
  transportationNoiseSources: {} as TransportationNoise,
  poiNoiseSources: [] as NoiseEvaluation[],
  flags: {} as Flags,
  noiseInsulation: {} as NoiseRegulation,
  quality: {} as Quality,
  userFeedback: [] as Feedback[]
};

const getters = {

};

const mutations = {
  setNoiseScores (state: State, noiseScores: APIResponse["noise_scores"])
  {
    state.noiseScores = noiseScores
  },
  setPOINoiseSources (state: State, poiNoiseSources: NoiseEvaluation[])
  {
    state.poiNoiseSources = poiNoiseSources
  },
  setTransportationNoiseSources (state: State, transportationNoiseSources: TransportationNoise)
  {
    state.transportationNoiseSources = transportationNoiseSources
  },
  setNoiseFlags (state: State, flags: Flags)
  {
    state.flags = flags
  },
  setNoiseInsulation (state: State, noiseInsulation: NoiseRegulation)
  {
    state.noiseInsulation = noiseInsulation
  },
  setQuality (state: State, quality: Quality)
  {
    state.quality = quality
  },
  setUserFeedback (state: State, feedback: string) 
  {
    state.userFeedback = JSON.parse(feedback)
  }
};

const actions = {

};

export default 
{
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
